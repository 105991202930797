import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})

/**
 * Index Component
 */
export class IndexComponent implements OnInit {

  sliderTopbar = true;
  /***
   * nav class set
   */
   navClass = 'nav-light';

  /**
   * Partners slider
   */
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: false
  };
 /**
   * Review Testimonial Data
   */
  reviewData = [
    {
      profile: "assets/images/real/MD.JPG",
      name: "Avasa Homes ",
      designation: "Special Feature",
      message: `" 100 % Vaastu Compliant
      No Common wallsy
      Power backup in common areas and 3 points in each flat
      Rain water harvesting pits.
      24/7 Security system with CCTV cameras
        "`
    },
    {
      profile: "assets/images/real/MD.JPG",
      name: "Avasa Homes ",
      designation: "Special Feature",
      message: `" Excellent cross ventilation & abundant natural light
      Car wash provision
      Grand entrance lobby
      Community hall for social gatherings
       "`
    },
    {
      profile: "assets/images/real/MD.JPG",
      name: "Avasa Homes ",
      designation: "Special Feature",
      message: `" Children play area and green area with landscaping
      24 hours water supply for Bore water and regularly operated
      Municipal drinking water
      Office room and common washrooms. "`
    },
   // {
    //  profile: "assets/images/client/04.jpg",
    //  name: "Christa Smith",
    //  designation: "Manager",
    //  message: `" According to most sources, Lorum Ipsum can be traced back to a text
    //  composed by Cicero in 45 BC. Allegedly, a Latin scholar established the origin of the text by compiling
   //   all the instances of the unusual word 'consectetur' he could find "`
  //  },
   // {
   //   profile: "assets/images/client/05.jpg",
    //  name: "Dean Tolle",
   //   designation: "Developer",
  //    message: `" It seems that only fragments of the original text remain in the
  //    Lorem Ipsum texts used today. The most well-known dummy text is the 'Lorem Ipsum', which is said to have
  //    originated in the 16th century. "`
  //  },
    {
      profile: "assets/images/real/MD.JPG",
      name: "Avasa Homes ",
      designation: "Special Feature",
      message: `" 
      Provision for spacious Library
      Additional Service Lift.
      Additional Service Lift.
      Wide parking place for 4 Wheelers and 2 Wheelers. "`
    }
  ];
/**
   * Blog Data
   */
 blogData = [
  {
    image: "assets/images/real/p1.jpg",
    title: "Luxury Apartment In Avasa Home",
    like: "33",
    message: "08",
    name: "Sigotam",
    date: "03rd October, 2022"
  },
  {
    image: "assets/images/real/p2.jpg",
    title: "Luxury Apartment In Avasa Home",
    like: "33",
    message: "08",
    name: "Sigotam",
    date: "03rd October, 2022"
  },
  {
    image: "assets/images/real/p3.jpg",
    title: "Luxury Apartment In Avasa Home",
    like: "33",
    message: "08",
    name: "Sigotam",
    date: "03rd October, 2022"
  }
  
];
  constructor() { }

  ngOnInit(): void {
  }

}
