import { Component, OnInit } from '@angular/core';

interface feature {
  icon: string;
  title: string;
};

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  /**
   * Clients Logo
   */
   featuresdata: feature[] = [
    {
      icon: "monitor",
      title: "DTCP Approved Project."
    },
    {
      icon: "heart",
      title: "RERA registered Project."
    },
    {
      icon: "eye",
      title: "Wide approach roads from all directions."
    },
    {
      icon: "bold",
      title: "Market & shopping malls"
    },
    {
      icon: "feather",
      title: "Reputed Educational Institutions"
    },
    {
      icon: "code",
      title: "Banks & Hospitals"
    },
    {
      icon: "user-check",
      title: "Wanaparthy Palace"
    },
    {
      icon: "git-merge",
      title: "Educational Institutions"
    },
    {
      icon: "settings",
      title: "Annus Academy High School"
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
