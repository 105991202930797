<!-- Start Hero -->
<section class="bg-half-170 d-table w-100" data-jarallax='{"speed": 0.5}'
    style="background: url('') top;" id="home">
</section>
<!--end section-->
<!-- End Hero -->
<!--Start Banner container-->
<div class="container-fluid mt-100 mt-60">
    <div class="bg-primary rounded shadow py-2">
       
        <!-- <div class="embed-responsive embed-responsive-4by3" >
            <iframe [class.thumbnail]=" " [src]="safeSrc" width="100%" height="'400px'" frameborder="0" webkitallowfullscreen mozallowfullscreen
            allowfullscreen></iframe> -->

            <iframe src="https://www.youtube.com/embed/M5cyJisOLtw?autoplay=1&showinfo=0&loop=1&list=PLPKL0OcZN52DwERv-NOaq6tT5qQITeHh8&rel=0" onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));' style="height:600px;width:100%;border:solid 4px #bba104;overflow:hidden;"></iframe>
            
        </div>
        <div class="container my-md-5">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title">
                     </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
<!-- End Banner container--> 
<!-- Start -->
<section class="mt-5 pt-4">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 mt-4">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Avasa Homes" class="customer-testi">
                                <img src="assets/images/real/b1.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Avasa Homes" class="customer-testi">
                                <img src="assets/images/real/b10.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Avasa Homes" class="customer-testi">
                                <img src="assets/images/real/b6.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Avasa Homes" class="customer-testi">
                                <img src="assets/images/real/b10.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Avasa Homes" class="customer-testi">
                                <img src="assets/images/real/b7.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Avasa Homes" class="customer-testi">
                                <img src="assets/images/real/b6.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Avasa Homes" class="customer-testi">
                                <img src="assets/images/real/b7.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Avasa Homes" class="customer-testi">
                                <img src="assets/images/real/b8.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Avasa Homes" class="customer-testi">
                                <img src="assets/images/real/b9.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Avasa Homes" class="customer-testi">
                                <img src="assets/images/real/b10.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Avasa Homes" class="customer-testi">
                                <img src="assets/images/real/b11.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Avasa Homes" class="customer-testi">
                                <img src="assets/images/real/b6.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Avasa Homes" class="customer-testi">
                                <img src="assets/images/real/b13.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->
       <!--Start  container Broucher Launch --> 
<div class="container mt-100 mt-60">
  <div class="row align-items-center">
      <div class="col-lg-4">
          <div class="section-title text-center text-lg-start mb-4 mb-lg-0">
              <span class="badge rounded-pill bg-soft-primary">Avasa </span>
              <h4 class="title mt-3 mb-4">Broucher Launch</h4>
              <p class="text-muted mx-auto mx-lg-star para-desc mb-0">#<span
                      class="text-primary fw-bold">Fortune Infra Projects</span> Avasa Homes A Gated community Flats project, Brochure launch on 2-10-2022.  
                      <span class="text-primary fw-bold">Chief Guest</span> Singireddy Niranjan Reddy, Minister Of Agriculture, Co-operation and Marketing Governament of Telangana.</p>
              <div class="mt-4 d-none d-lg-block">
                <a href="assets/images/real/fortunewanaparthy.pdf" class="btn btn-primary">Download Brochure</a>
              </div>
          </div>
      </div>
      <!--end col-->

      <div class="col-lg-8 mt-4 mt-lg-0 pt-2 pt-lg-0">
          <owl-carousel-o id="customer-testi" [options]="customOptions">
              <ng-template carouselSlide>
                  <div class="tiny-slide">
                      <div
                          class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                          <div class="shop-image position-relative overflow-hidden shadow">
                              <a routerLink="/Avasa Homes"><img src="assets/images/real/p1.jpg"
                                      class="img-fluid" alt=""></a>
                              <ul class="list-unstyled shop-icons">
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-estate icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-heart icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-camera icons"></i></a></li>
                              </ul>
                          </div>
                          <div class="card-body content p-4">
                              <a href="/Avasa Homes" class="text-dark product-name h6">Director Sigotam Bhaskar Reddy </a>
                              <ul class="list-unstyled text-muted mt-2 mb-0">
                                 <!-- <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                  <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li> --> 
                              </ul>
                               <!--<ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                 <li class="list-inline-item"><b>$ 44000</b></li>
                                  <li class="list-inline-item text-muted">$ 850 / sqft</li> 
                              </ul> --> 
                          </div>
                      </div>
                  </div>
              </ng-template>
              <ng-template carouselSlide>
                  <div class="tiny-slide">
                      <div
                          class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                          <div class="shop-image position-relative overflow-hidden shadow">
                              <a routerLink="/Avasa Homes"><img src="assets/images/real/p2.jpg"
                                      class="img-fluid" alt=""></a>
                              <ul class="list-unstyled shop-icons">
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-estate icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-heart icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-camera icons"></i></a></li>
                              </ul>
                          </div>
                          <div class="card-body content p-4">
                              <a href="/Avasa Homes" class="text-dark product-name h6">Director & Managing Director Vishal Reddy</a>
                              <ul class="list-unstyled text-muted mt-2 mb-0">
                                  <!--  <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                  <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li> --> 
                              </ul>
                              
                          </div>
                      </div>
                  </div>
              </ng-template>
              <ng-template carouselSlide>
                  <div class="tiny-slide">
                      <div
                          class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                          <div class="shop-image position-relative overflow-hidden shadow">
                              <a routerLink="/Avasa Homes"><img src="assets/images/real/p8.jpg"
                                      class="img-fluid" alt=""></a>
                              <ul class="list-unstyled shop-icons">
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-estate icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-heart icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-camera icons"></i></a></li>
                              </ul>
                          </div>
                          <div class="card-body content p-4">
                              <a href="/Avasa Homes" class="text-dark product-name h6">Singotam Balreddy Inviting Niranjan Reddy </a>
                              <ul class="list-unstyled text-muted mt-2 mb-0">
                                 <!--  <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                  <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li> --> 
                              </ul>
                          </div>
                      </div>
                  </div>
              </ng-template>
              <ng-template carouselSlide>
                  <div class="tiny-slide">
                      <div
                          class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                          <div class="shop-image position-relative overflow-hidden shadow">
                              <a routerLink="/Avasa Homes"><img src="assets/images/real/p4.jpg"
                                      class="img-fluid" alt=""></a>
                              <ul class="list-unstyled shop-icons">
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-estate icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-heart icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-camera icons"></i></a></li>
                              </ul>
                          </div>
                          <div class="card-body content p-4">
                              <a href="/Avasa Homes" class="text-dark product-name h6"> Chief Guest : Singireddy Niranjan Reddy, Minister of Agriculture</a>
                              <ul class="list-unstyled text-muted mt-2 mb-0">
                                  <!-- <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                  <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li> --> 
                              </ul>
                             
                          </div>
                      </div>
                  </div>
              </ng-template>
              <ng-template carouselSlide>
                  <div class="tiny-slide">
                      <div
                          class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                          <div class="shop-image position-relative overflow-hidden shadow">
                              <a routerLink="/Avasa Homes"><img src="assets/images/real/p5.jpg"
                                      class="img-fluid" alt=""></a>
                              <ul class="list-unstyled shop-icons">
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-estate icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-heart icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-camera icons"></i></a></li>
                              </ul>
                          </div>
                          <div class="card-body content p-4">
                              <a href="/Avasa Homes" class="text-dark product-name h6"> Avasa Home Broucher Launch </a>
                              <ul class="list-unstyled text-muted mt-2 mb-0">
                                 <!-- <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                  <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>  --> 
                              </ul>
                             
                          </div>
                      </div>
                  </div>
              </ng-template>
              <ng-template carouselSlide>
                  <div class="tiny-slide">
                      <div
                          class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                          <div class="shop-image position-relative overflow-hidden shadow">
                              <a routerLink="/Avasa Homes"><img src="assets/images/real/p6.jpg"
                                      class="img-fluid" alt=""></a>
                              <ul class="list-unstyled shop-icons">
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-estate icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-heart icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-camera icons"></i></a></li>
                              </ul>
                          </div>
                          <div class="card-body content p-4">
                              <a href="/Avasa Homes" class="text-dark product-name h6">Speach by : Singireddy Niranjan Reddy, Minister of Agriculture </a>
                              <ul class="list-unstyled text-muted mt-2 mb-0">
                                  <!-- <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                  <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>  --> 
                              </ul>
                             
                          </div>
                      </div>
                  </div>
              </ng-template>
              <ng-template carouselSlide>
                  <div class="tiny-slide">
                      <div
                          class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                          <div class="shop-image position-relative overflow-hidden shadow">
                              <a routerLink="/Avasa Homes"><img src="assets/images/real/p7.jpg"
                                      class="img-fluid" alt=""></a>
                              <ul class="list-unstyled shop-icons">
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-estate icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-heart icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-camera icons"></i></a></li>
                              </ul>
                          </div>
                          <div class="card-body content p-4">
                              <a href="/Avasa Homes" class="text-dark product-name h6">Avasa Home Broucher Launch</a>
                              <ul class="list-unstyled text-muted mt-2 mb-0">
                                  <!-- <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                  <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li> -->
                              </ul>
                             
                          </div>
                      </div>
                  </div>
              </ng-template>
              <ng-template carouselSlide>
                  <div class="tiny-slide">
                      <div
                          class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                          <div class="shop-image position-relative overflow-hidden shadow">
                              <a routerLink="/Avasa Homes"><img src="assets/images/real/p3.jpg"
                                      class="img-fluid" alt=""></a>
                              <ul class="list-unstyled shop-icons">
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-estate icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-heart icons"></i></a></li>
                                  <li class="list-inline-item"><a href="/Avasa Homes"
                                          class="btn btn-icon btn-pills btn-primary"><i
                                              class="uil uil-camera icons"></i></a></li>
                              </ul>
                          </div>
                          <div class="card-body content p-4">
                              <a href="/Avasa Homes" class="text-dark product-name h6">Lamp lighting Ceremony & Pooja</a>
                              <ul class="list-unstyled text-muted mt-2 mb-0">
                                   <!-- <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                  <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li> -->
                              </ul>
                              
                          </div>
                      </div>
                  </div>
              </ng-template>
          </owl-carousel-o>
      </div>
      <!--end col-->
  </div>
  <!--end row-->
</div>
<!--end container-->
<!-- Testi Start -->
<section class="section pb-0 mb-6" style="margin-bottom:100px;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-2">
          <!--<h6>We believe in building each other and hence</h6>-->
          <h4 class="title mb-4" style="font-weight: bold;">Isometric View </h4>
          <p class="text-muted para-desc mx-auto mb-0"> # <span
              class="text-primary fw-bold">Avasa </span>  Homes create a home
              for you and your family with love, comfort and beautiful memories.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-12 mt-4 pt-2">
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
               <img src="assets/images/real/fltNo2.jpg" class="img-fluid avatar  mx-auto" alt="Isometric View Flot No 2">
                <h6 class="text-" style="margin-top: 14px;
                font-weight: bolder; color:#777777;">3D View Facing - East </h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
               <img src="assets/images/real/Plan1.jpg" class="img-fluid avatar  mx-auto" alt="Isometric View Flot No 2">
                <h6 class="text-" style="margin-top: 14px;
                font-weight: bolder; color:#777777;">Flat No 1 1219 Sft</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
               <img src="assets/images/real/Plan2.jpg" class="img-fluid avatar  mx-auto" alt="Isometric View Flot No 2">
                <h6 class="text-" style="margin-top: 14px;
                font-weight: bolder; color:#777777;">Flat No 2 1262 Sft</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
               <img src="assets/images/real/Plan3.jpg" class="img-fluid avatar  mx-auto" alt="Isometric View Flot No 2">
                <h6 class="text-" style="margin-top: 14px;
                font-weight: bolder; color:#777777;">Flat No 3 1627 Sft</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
               <img src="assets/images/real/Plan4.jpg" class="img-fluid avatar  mx-auto" alt="Isometric View Flot No 2">
                <h6 class="text-" style="margin-top: 14px;
                font-weight: bolder; color:#777777;">Flat No 4 1175 Sft</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
               <img src="assets/images/real/Plan5.jpg" class="img-fluid avatar  mx-auto" alt="Isometric View Flot No 2">
                <h6 class="text-" style="margin-top: 14px;
                font-weight: bolder; color:#777777;">Flat No 5 1162 Sft</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
               <img src="assets/images/real/Plan6.jpg" class="img-fluid avatar  mx-auto" alt="Isometric View Flot No 2">
                <h6 class="text-" style="margin-top: 14px;
                font-weight: bolder; color:#777777;">Flat No 6 1175 Sft</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
               <img src="assets/images/real/Plan7.jpg" class="img-fluid avatar  mx-auto" alt="Isometric View Flot No 2">
                <h6 class="text-" style="margin-top: 14px;
                font-weight: bolder; color:#777777;">Flat No 7 1627 Sft</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
               <img src="assets/images/real/Plan8.jpg" class="img-fluid avatar  mx-auto" alt="Isometric View Flot No 2">
                <h6 class="text-" style="margin-top: 14px;
                font-weight: bolder; color:#777777;">Flat No 8 1262 Sft</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
               <img src="assets/images/real/Plan9.jpg" class="img-fluid avatar  mx-auto" alt="Isometric View Flot No 2">
                <h6 class="text-" style="margin-top: 14px;
                font-weight: bolder; color:#777777;">Flat No 9 1212 Sft </h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
               <img src="assets/images/real/Entrance.jpg" class="img-fluid avatar  mx-auto" alt="Isometric View Flot No 2">
                <h6 class="text-" style="margin-top: 14px;
                font-weight: bolder; color:#777777;">Garden Area 1420 sft</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
               <img src="assets/images/real/fltNo3.jpg" class="img-fluid avatar  mx-auto" alt="Isometric View Flot No 2">
                <h6 class="text-" style="margin-top: 14px;
                font-weight: bolder; color:#777777;">3D View Facing - west</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
               <img src="assets/images/real/ParkingPlan.jpg" class="img-fluid avatar  mx-auto" alt="Avasa Parking Plan">
                <h6 class="text-" style="margin-top: 14px;
                font-weight: bolder; color:#777777;">Parking Plan </h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
               <img src="assets/images/real/fltNo2.jpg" class="img-fluid avatar  mx-auto" alt="Isometric View Flot No 2">
                <h6 class="text-" style="margin-top: 14px;
                font-weight: bolder; color:#777777;">3D View facing East</h6>
              </div>
            </div>
          </ng-template>
        
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
               <img src="assets/images/real/fltNo2.jpg" class="img-fluid avatar  mx-auto" alt="ISOMETRIC VIEW Flot No 2">
                <h6 class="text-" style="margin-top: 14px;
                font-weight: bolder; color:#777777;">3D View Facing East </h6>
              </div>
            </div>
          </ng-template>
        
        </owl-carousel-o>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

  <!--review -->
<div class="container-fluid mt-100 mt-60">
        <div class="rounded shadow py-5" style="background: url('assets/images/real/LocationPlan.jpg') center center;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="rounded p-4 bg-light">
                            <app-review-testmonial [reviewData]="reviewData"></app-review-testmonial>
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </div>
    </div>
    <!--End review -->
    <!--Start Key Features -->
    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Key Features</h4>
              <p class="text-muted para-desc mx-auto mb-0"> # <span
                  class="text-primary fw-bold">Avasa</span> Avasa Homes create a home
                  for you and your family with love, comfort and beautiful memories with world class amenities.</p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <app-features></app-features>
      </div>
      <!--end container-->
<!--Start Contact  container-->
<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Contact -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6  mt-4 pt-2">
        <div class="card shadow rounded border-0">
          <div class="card-body py-5">
            <h4 class="card-title">Get In Touch !</h4>
            <div class="custom-form mt-3">
              <form method="post" name="myForm" onsubmit="return validateForm()">
                <p id="error-msg" class="mb-0"></p>
                <div id="simple-msg"></div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Your Name <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                        <input name="name" id="name" type="text" class="form-control ps-5" placeholder="Name :">
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Your Email <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                        <input name="email" id="email" type="email" class="form-control ps-5" placeholder="Email :">
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12">
                    <div class="mb-3">
                      <!--<label class="form-label">Subject</label>
                      <div class="form-icon position-relative">
                        <i-feather name="book" class="fea icon-sm icons"></i-feather>
                        <input name="subject" id="subject" class="form-control ps-5" placeholder="subject :">
                      </div> -->
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Comments <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                        <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                          placeholder="Message :"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="d-grid">
                      <button type="submit" id="submit" name="send" class="btn btn-primary">Send Message</button>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
            </div>
            <!--end custom-form-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-8 col-md-6 ps-md-3 pr-md-3 mt-4 pt-2">
        <div class="card map map-height-two rounded map-gray border-0">
          <div class="card-body p-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3828.299215926525!2d78.05252931486227!3d16.358713188702666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb78944013c7712c7!2zMTbCsDIxJzMxLjQiTiA3OMKwMDMnMTcuMCJF!5e0!3m2!1sen!2sin!4v1666861263612!5m2!1sen!2sin"
              style="border:0" class="rounded" allowfullscreen=""></iframe>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
<!--Start Contact  container-->
<!-- FAQ n Contact End -->
<section class="section pt-0" style="background: url('assets/images/real/build.png') bottom no-repeat;">
  <div class="container">
      <div class="row justify-content-center">
          <div class="col-12 text-center">
              <div class="section-title mb-4 pb-2">
                  <h4 class="title mb-4">Latest Blog & News</h4>
                  <p class="text-muted para-desc mx-auto mb-0">Avasa   <span
                          class="text-primary fw-bold">Homes</span> create a home
                          for you and your family with love, comfort and beautiful memories.</p>
              </div>
          </div>
          <!--end col-->
      </div>
      <!--end row-->
      <app-blog [blogData]="blogData"></app-blog>
     
  </div>
  <!--end container-->
  
</section>

<!-- Back to top -->
<a href="#" [ngxScrollTo]="'#home'" id="back-to-top" class="btn btn-icon btn-primary back-to-top">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->